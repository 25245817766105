<template>
  <div></div>
</template>
<script>
import { wxBind, isBind } from "@/api/user";
export default {
  data() {
    return {
      code: "",
      state: "",
      loading: null,
    };
  },
  created() {
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.7)",
    });
    // setTimeout(() => {
    //   loading.close();
    // }, 2000);
    this.code = this.$route.query.code || "";
    this.state = this.$route.query.state || "";
    if (this.$store.state.access_token) {
      this.wxBind();
    } else {
      this.isBind();
    }
  },
  methods: {
    wxBind() {
      wxBind(this.code)
        .then(() => {
          console.log('22222')
          this.loading.close();
          this.$message({
            message: "绑定成功！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              this.$router.push("/Info");
            },
          });
        })
        .catch(() => {
          console.log('11111')
          this.loading.close();
          this.$router.go(-1);
        });
    },
    isBind() {
      isBind(this.code)
        .then((res) => {
          this.loading.close();
          if (res.data.status == 1) {
            localStorage.setItem("access_token", res.data.token);
            this.$store.state.access_token = res.data.token;
            localStorage.setItem("typeInfo", res.data.type);
            this.$store.state.typeInfo = res.data.type;
            localStorage.setItem("userId", res.data.userId);
            this.$store.state.userId = res.data.userId;
            let type = res.data.type;
            this.$message({
              message: "登录成功！", //提示的信息
              type: "success", //类型是成功
              duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
              onClose: () => {
                //跳转页面或执行方法
                if (type == 0) {
                  this.$router.push({ name: "BasicInformation" });
                } else {
                  this.$router.push("/");
                }
              },
            });
          } else {
            let data = {
              unionid: res.data.unionid,
            };
            this.$router.push({
              name: "WechatCallback",
              query: data,
            });
          }
        })
        .catch(() => {
          console.log('11111')
          this.loading.close();
          this.$router.go(-1);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.flex {
  background: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .content {
    width: fit-content;
    .title {
      font-size: 22px;
      font-weight: bolder;
      width: fit-content;
      margin: 0 auto;
      margin-bottom: 40px;
    }
    a {
      display: block;
      margin: 0 auto;
      width: 160px;
      height: 45px;
      line-height: 45px;
      border-radius: 5px;
      border: 1px solid var(--main-color);
      color: var(--main-color);
    }
  }
}
</style>
